import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/src/components/capital/Layouts/LayoutMDX.tsx";
import { Description, Error, Header, Label, Text } from '@opengov/component-library/capital';
import { Grid, TypeExample, UsageBlock } from '../../../components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Body Copy`}</h2>
    <p>{`See the `}<strong parentName="p">{`text component`}</strong>{` or `}<strong parentName="p">{`description component`}</strong>{`.`}</p>
    <TypeExample sampleText="Capital is OpenGov's design system." sampleTitle="Body" description={'Body copy is used'} mdxType="TypeExample" />
    <TypeExample sampleText={<Text size="small" mdxType="Text">Capital is OpenGov's design system.</Text>} sampleTitle="Small Body" description={'Body copy is used'} mdxType="TypeExample" />
    <TypeExample sampleText={<Description mdxType="Description">Capital is OpenGov's design system.</Description>} sampleTitle="Description" description={'Descriptions are used as secondary information to body copy.'} mdxType="TypeExample" />
    <TypeExample sampleText={<Description variant="condensed" mdxType="Description">Capital is OpenGov's design system.</Description>} sampleTitle="Condensed Description" description={'Descriptions are used as secondary information to body copy.'} mdxType="TypeExample" />
    <h3>{`Usage`}</h3>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>Use varying sizes and colors to add hierarchy to text.</p>
    <p>Use condensed text where horizontal space is limited.</p>
    <p>Use headers or labels instead of body copy when adding titles.</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>Use small or minimum sizes as the default.</p>
    <p>Combine condensed text with the default font size.</p>
    <p>Use bold or medium body copy as labels or headers.</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Headers`}</h2>
    <p>{`See the `}<strong parentName="p">{`header component`}</strong>{`.`}</p>
    <TypeExample sampleText={<Header variant="hero" mdxType="Header">Hello world</Header>} sampleTitle="Hero Header" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleText={<Header variant="h1" mdxType="Header">Hello world</Header>} sampleTitle="H1 Header" description={'H1 Headers should always be used as the page title. Nothing should sit about this header except the navigation and breadcrumbs.'} mdxType="TypeExample" />
    <TypeExample sampleText={<Header variant="h2" mdxType="Header">Hello world</Header>} sampleTitle="H2 Header" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleText={<Header variant="h3" mdxType="Header">Hello world</Header>} sampleTitle="H3 Header" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleText={<Header variant="h4" mdxType="Header">Hello world</Header>} sampleTitle="H4 Header" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleText={<Header variant="h5" mdxType="Header">Hello world</Header>} sampleTitle="H5 Header" description={'Description about uses'} mdxType="TypeExample" />
    <h3>{`Usage`}</h3>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Labels`}</h2>
    <p>{`See the `}<strong parentName="p">{`label component`}</strong>{`.`}</p>
    <TypeExample sampleText={<Label as="span" mdxType="Label">Hello world</Label>} sampleTitle="Form label" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleText={<Label variant="group" as="span" mdxType="Label">Hello world</Label>} sampleTitle="Group label" description={'Description about uses'} mdxType="TypeExample" />
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Status Messaging`}</h2>
    <p>{`See the `}<strong parentName="p">{`status messaging`}</strong>{`.`}</p>
    <TypeExample sampleText={<Error mdxType="Error">Capital is OpenGov's design system.</Error>} sampleTitle="Error message" description={'Description about uses'} mdxType="TypeExample" />
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`Configurations`}</h2>
    <h3>{`Font Family`}</h3>
    <TypeExample sampleValue="$base-font" sampleText="Hello world" sampleTitle="Benton Sans" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleValue="$base-font-condensed" sampleText={<Text font="condensed" mdxType="Text">Hello world</Text>} sampleTitle="Benton Sans Condensed" description={'Description about uses'} mdxType="TypeExample" />
    <h3>{`Font Size`}</h3>
    <TypeExample sampleValue="$font-size-maximum" sampleText="Hello world" sampleStyle={{
      fontSize: '3rem'
    }} sampleTitle="Maximum" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleValue="$font-size-xxlarge" sampleText="Hello world" sampleStyle={{
      fontSize: '2.5rem'
    }} sampleTitle="XXLarge" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleValue="$font-size-xxlarge" sampleText="Hello world" sampleStyle={{
      fontSize: '1.75rem'
    }} sampleTitle="XLarge" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleValue="$font-size-large" sampleText="Hello world" sampleStyle={{
      fontSize: '1.25rem'
    }} sampleTitle="Large" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleValue="$font-size-default" sampleText={<Text size="default" mdxType="Text">Hello world</Text>} sampleTitle="Default" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleValue="$font-size-small" sampleText={<Text size="small" mdxType="Text">Hello world</Text>} sampleTitle="Small" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleValue="$font-size-minimum" sampleText={<Text size="minimum" mdxType="Text">Hello world</Text>} sampleTitle="Minimum" description={'Description about uses'} mdxType="TypeExample" />
    <h3>{`Weight`}</h3>
    <TypeExample sampleValue="$font-weight-regular" sampleText={<Text weight="regular" mdxType="Text">Hello world</Text>} sampleTitle="Regular" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleValue="$font-weight-medium" sampleText={<Text weight="medium" mdxType="Text">Hello world</Text>} sampleTitle="Medium" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleValue="$font-weight-bold" sampleText={<Text weight="bold" mdxType="Text">Hello world</Text>} sampleTitle="Bold" description={'Description about uses'} mdxType="TypeExample" />
    <h3>{`Color`}</h3>
    <p>{`For semantic text colors, see `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/styles/colors"
        }}>{`Colors`}</a></strong>{`.`}</p>
    <TypeExample sampleValue="$color-gray-1000" sampleText={<Text color="default" mdxType="Text">Hello world</Text>} sampleTitle="Default" description={'Description about uses'} mdxType="TypeExample" />
    <TypeExample sampleValue="$color-gray-700" sampleText={<Text color="light" mdxType="Text">Hello world</Text>} sampleTitle="Light" description={'Description about uses'} mdxType="TypeExample" />
    <h2>{`Why Benton Sans`}</h2>
    <p>{`Benton Sans aligns perfectly with the traits, tone and requirements of the system. Benton Sans is a digital typeface family begun by Tobias Frere-Jones, who studied drawings of Morris Fuller Benton's 1908 typeface News Gothic.
Morris Benton Fuller is an American Type Designer whose typeface, Century Schoolbook, is required on all material submitted to the Supreme Court.`}</p>
    <h3>{`Benton Sans Characteristics`}</h3>
    <Grid columns="2" mdxType="Grid">
  <div>
    <h4>Credible</h4>
    Benton feels credible – especially its numbers. One PM with a background in finance said, “These look like an accountant’s numbers.”
  </div>
  <div>
    <h4>Relatable</h4>
    Even though the face is refined and reserved, it has an organic quality and subtle variations. It avoids feeling severe and and moves toward a humanist tone of voice, the perfect balance for OpenGov.
  </div>
  <div>
    <h4>Flexible</h4>
    Benton offers most of the flexibility that OpenGov requires. Some features that make it especially suited for OpenGov: multiple widths, weight duplexing, and tabular figures.
  </div>
  <div>
    <h4>Classic</h4>
    With roots in the classic typeface News Gothic, Benton feels universal and established.
  </div>
    </Grid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      